import React, { useCallback } from "react";
import Script from 'react-load-script'
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"

const IndexPage = () => {
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  const onLoadFormrun = useCallback(() => {
    window.Formrun?.init('.formrun')
  }, [])

  return (
    <>
      <Script onLoad={onLoadFormrun} url="https://sdk.form.run/js/v2/formrun.js"></Script>

      {
        <Layout>
          <Seo
            keywords="お問い合わせ,トナリノ,デザイン,デザイン相談"
            description="サービスにかんするご質問やお仕事のご依頼などはこちらからご連絡ください。"
            ogTitle="お問い合わせ"
            ogDescription="トナリノへのお問い合わせページです"
            ogUrl="https://tonarino.co/hello"
          />

            <PageTitleBox title="お問い合わせ" />
            <div className="container mb-5 pageBodyBox">
              <p data-formrun-hide-if-confirm>
                トナリノにご興味を持っていただき誠にありがとうございます。
                <br />
                サービスについてのご質問やお仕事のご依頼などはこちらからご連絡ください。
              </p>

              <section>
                <form
                  className="formrun"
                  action="https://form.run/api/v1/r/wn9radwzq9rdp6bsqgdp68er"
                  method="post"
                >
                  <div className="form-group row mb-3">
                    <label
                      htmlFor="name"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      お名前
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        id="name"
                        name="お名前"
                        type="text"
                        className="form-control"
                        data-formrun-required
                        placeholder="田中 太郎"
                      />
                      <div data-formrun-show-if-error="お名前" className="mt-1 text-danger">
                        お名前を入力してください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="email"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      メールアドレス
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        id="email"
                        name="メールアドレス"
                        type="text"
                        data-formrun-type="email"
                        className="form-control"
                        data-formrun-required
                        placeholder="mail@tonarino.co"
                        required
                      />
                      <div data-formrun-show-if-error="メールアドレス" className="mt-1 text-danger">
                        メールアドレスを正しく入力してください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="content"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      お問い合わせ内容
                    </label>
                    <div className="col-12 col-md-8">
                      <div className="form-check">
                        <input
                          id="contactContent1"
                          className="form-check-input"
                          type="radio"
                          name="お問い合わせ内容"
                          value="サービスについてのご質問"
                          data-formrun-required
                          required
                        />
                        <label className="form-check-label" for="contactContent1">
                          サービスについてのご質問
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="contactContent2"
                          className="form-check-input"
                          type="radio"
                          name="お問い合わせ内容"
                          value="費用・お見積りについてのご質問"
                          data-formrun-required
                          required
                        />
                        <label className="form-check-label" for="contactContent2">
                          費用・お見積りについてのご質問
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="contactContent3"
                          className="form-check-input"
                          type="radio"
                          name="お問い合わせ内容"
                          value="お仕事・お打ち合わせのご依頼"
                          data-formrun-required
                          required
                        />
                        <label className="form-check-label" for="contactContent3">
                          お仕事・お打ち合わせのご依頼
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="contactContent4"
                          className="form-check-input"
                          type="radio"
                          name="お問い合わせ内容"
                          value="その他のご連絡"
                          data-formrun-required
                          required
                        />
                        <label className="form-check-label" for="contactContent4">
                          その他のご連絡
                        </label>
                      </div>
                      <div data-formrun-show-if-error="お問い合わせ内容" className="mt-1 text-danger">
                        お問い合わせ内容にチェックを入れてください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="content-large"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      お問い合わせ
                    </label>
                    <div className="col-12 col-md-8">
                      <textarea
                        id="content-large"
                        name="お問い合わせ"
                        className="form-control"
                        data-formrun-required
                        col="6"
                      ></textarea>
                      <div data-formrun-show-if-error="お問い合わせ" className="mt-1 text-danger">
                        お問い合わせ内容を入力してください
                      </div>
                    </div>
                  </div>

                  <div class="_formrun_gotcha">
                    <label for="_formrun_gotcha">If you are a human, ignore this field</label>
                    <input type="text" name="_formrun_gotcha" id="_formrun_gotcha" tabindex="-1" />
                  </div>

                  <div className="g-recaptcha d-flex justify-content-center" data-sitekey="6LevJR8cAAAAAKEo17K9GEuRnFaAjbsiBWiwu9hG"></div>

                  <p className="text-center mt-4 mb-5">
                    <button
                      type="submit"
                      data-formrun-error-text="未入力の項目があります"
                      data-formrun-submitting-text="送信中..."
                      className="btn btn-primary btn-fixed-width rounded-pill py-3"
                    >
                      送信
                    </button>
                  </p>
                </form>
              </section>

              <p className="mb-0">
                個人情報の取り扱い詳細は「
                <Link to="/privacy">プライバシーポリシー</Link>
                」をご覧ください。
              </p>
            </div>

        </Layout>
      }
    </>)
}

export default IndexPage
